import React, {useState, useEffect} from 'react'
import ReactGA from 'react-ga4';
import {FaRedoAlt, FaLink, FaImage} from "react-icons/fa";
import {FiShare, FiX, FiExternalLink} from "react-icons/fi";

function App() {

  const [isLoading, setLoading] = useState(true);
  
  function someRequest(){
    return new Promise(resolve => setTimeout(() => resolve(), 0));
  } 

  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector(".loader-container");
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    });
  });

  const queryParameters = new URLSearchParams(window.location.search)
  const [currentDistance, setCurrentDistance] = useState(queryParameters.get("d") ? decodeURIComponent(queryParameters.get("d")).replaceAll("_", ".") : undefined);
  const [currentTime, setCurrentTime] = useState(queryParameters.get("t") ? decodeURIComponent(queryParameters.get("t")).replaceAll("_", ".") : undefined);
  const [currentPace, setCurrentPace] = useState(queryParameters.get("p") ? decodeURIComponent(queryParameters.get("p")).replaceAll("_", ".") : undefined);
  const [currentSpeed, setCurrentSpeed] = useState(queryParameters.get("s") ? decodeURIComponent(queryParameters.get("s")).replaceAll("_", ".") : undefined);

const Header = () => {
  window.history.replaceState(null, "", window.location.pathname);
  return (
    <header>
      <h1>RUNNER<br/>CONVERTER</h1>
    </header>
  )
}

const shareAction = () => {
  ReactGA.event({
    category: "Support",
    action: "Click Share Button",
    label: "Share",
  });
  if (navigator.share) {
    navigator
      .share({
        url: "https://runner-converter.com",
        title: "Runner Converter",
      })
      .then(() => {
      })
      .catch(error => {
        console.error('Something went wrong', error);
      });
  }
};

const HelpButton = () => {
  return (
    <button className="openOptions" onClick={shareAction}><FiShare/></button>
  )
}

const UnitSwitch = ({toggle, handleToggleChange}) => {
  return (
      <div className='toggle-container' onClick={handleToggleChange}>
        <div className={`toggle-button ${toggle ? "mi" : "km"}`}>
          {toggle ? "mi" : "km"}</div>
      </div>
    )
}

const [toggle,setToggle] = useState(false);

const handleToggleChange = () => {
  setToggle(!toggle);
  setCurrentTime(undefined)
  setCurrentDistance(undefined)
  setCurrentPace(undefined)
  setCurrentSpeed(undefined)
  
  ReactGA.event({
    category: "Settings",
    action: "Change unit",
    label: toggle ? "km" : "mi",
  });
}

function clearInputs() {
  ReactGA.event({
    category: "Settings",
    action: "Reset values",
    label: "Reset",
  });
  setCurrentTime(undefined)
  setCurrentDistance(undefined)
  setCurrentPace(undefined)
  setCurrentSpeed(undefined)
}

const WorldRecords = () => {
  function setTimer(t,d,p,s,c) {
      ReactGA.event({
        category: "Input",
        action: "Use WR",
        label:c,
      });
      setCurrentTime(t)
      setCurrentDistance(d)
      setCurrentPace(p)
      setCurrentSpeed(s)
    }

  return(
    <div className="slider">
      <div>
        <div className="champ" id="Bolt">
          <img src="usain_bolt.jpg" alt="Usain Bolt" onClick={() => setTimer("00:00:09",0.1,"1:35",37.89, "Bolt")}></img>
          <p>Usain Bolt</p>
        </div>
        <div className="champ" id="Cheptegei">
          <img src="cheptegei.jpg" alt="Joshua Cheptegei" onClick={() => setTimer("26:11",10,"2:37",22.92,"Cheptegei")}></img>
          <p>Joshua Cheptegei</p>
        </div>
        <div className="champ" id="Kiplimo">
          <img src="kiplimo.jpg" alt="Jacob Kiplimo" onClick={() => setTimer("57:31",21.0975,"2:43",22.01, "Kiplimo")}></img>
          <p>Jacob Kiplimo</p>
        </div>
        <div className="champ" id="Kiptum">
          <img src="kiptum.jpg" alt="Kelvin Kiptum" onClick={() => setTimer("2:00:35",42.195,"2.51",21, "Kiptum")}></img>
          <p>Kelvin Kiptum</p>
        </div>
      </div>
    </div>
  )
}

const OneDigit = /^[1-9]$/;
const TwoDigits = /^[0-5][0-9]$/;
const ThreeDigits = /^[0-9][0-5][0-9]$/;
const FourDigits = /^[0-5][0-9][0-5][0-9]$/;
const FiveDigits = /^[0-9][0-5][0-9][0-5][0-9]$/;
const SixDigits = /^[0-9][0-9][0-5][0-9][0-5][0-9]$/;

const distanceFormat = /^\d*(\.\d{0,4})?$/;
const timeFormat = /^(?:(?:(\d+):)?([0-5]?\d):)?([0-5]?\d)$/;
const paceFormat = /^(([0]?[0-5][0-9]|[0-9]):([0-5][0-9]))$/;
const speedFormat = /^\d*(\.\d{0,2})?$/;

const Distance = () => {
  const [distance, setDistance] = useState(currentDistance)

    const FocusOut = (e) => {
      if (distanceFormat.test(e.target.value) && e.target.value) {
        ReactGA.event({
          category: "Input",
          action: "Type distance",
          label: e.target.value,
        });
        setCurrentDistance(distance)
        if(!currentSpeed && currentTime) {
          setCurrentSpeed(calculateSpeed(currentTime, distance))
          setCurrentPace(speedToPace(calculateSpeed(currentTime, distance)))
        }
        
        if (currentSpeed) {
          setCurrentTime(calculateTime(distance, currentSpeed))
        }

        if (!currentTime && currentSpeed) {
          setCurrentTime(calculateTime(distance, currentSpeed))
        }
        
      }
      else if (!e.target.value){
        setCurrentDistance(undefined)
      }
      else { 
        ReactGA.event({
          category: "Input",
          action: "Distance input error",
          label: e.target.value,
        });
        alert('Wrong format')
        e.target.value=''
      }
      e.preventDefault();
    }
    
    const setMarathon = () => {
      setCurrentDistance(toggle ? 26.2 : 42.195)
      if (currentTime && !currentSpeed) {
        setCurrentSpeed(calculateSpeed(currentTime, toggle ? 26.2 : 42.195))
        setCurrentPace(speedToPace(calculateSpeed(currentTime, toggle ? 26.2 : 42.195)))
      }
      if (currentSpeed && !currentTime) { setCurrentTime(calculateTime(toggle ? 26.2 : 42.195, currentSpeed))}
      if (currentSpeed && currentTime) { setCurrentTime(calculateTime(toggle ? 26.2 : 42.195, currentSpeed))}
      ReactGA.event({
        category: "Input",
        action: "Use shortcut",
        label: "Marathon",
      });
    }
  
    const setSemi = () => {
      setCurrentDistance(toggle ? 13.1 : 21.0975)
      if (currentTime && !currentSpeed) {
        setCurrentSpeed(calculateSpeed(currentTime,toggle ? 13.1 : 21.0975))
        setCurrentPace(speedToPace(calculateSpeed(currentTime, toggle ? 13.1 : 21.0975)))
      }
      if (currentSpeed && !currentTime) { setCurrentTime(calculateTime(toggle ? 13.1 : 21.0975, currentSpeed))}
      if (currentSpeed && currentTime) { setCurrentTime(calculateTime(toggle ? 13.1 : 21.0975, currentSpeed))}
      ReactGA.event({
        category: "Input",
        action: "Use shortcut",
        label: "Semi-marathon",
      });
    }
  
    const setTen = () => {
      setCurrentDistance(10)
      if (currentTime && !currentSpeed) {
        setCurrentSpeed(calculateSpeed(currentTime, 10.00))
        setCurrentPace(speedToPace(calculateSpeed(currentTime, 10.00)))
      }
      if (currentSpeed && !currentTime) { setCurrentTime(calculateTime(10, currentSpeed))}
      if (currentSpeed && currentTime) { setCurrentTime(calculateTime(10, currentSpeed))}
      ReactGA.event({
        category: "Input",
        action: "Use shortcut",
        label: "10k",
      });
    }

    return (
      <div className='Field Distance'>
        <h2>Distance</h2>
        <label className='unit'>
          <span>
            <input  
              name='Distance' 
              id='Distance' 
              type='text'
              inputMode="decimal"
              placeholder='10.00'
              value={distance}
              onChange={(e) => setDistance(e.target.value.replace("," , "."))}
              onBlur={FocusOut}
              onKeyDown={handleKeyDown}
            />
          </span>
        &#160;{toggle ? "mi" : "km"}</label>
        <div className='shortcuts'>
          <button className='shortcut' onClick={setMarathon}>Marathon</button>
          <button className='shortcut' onClick={setSemi}>Half-marathon</button>
          <button className='shortcut' onClick={setTen}>10k</button>
        </div>
      </div>
    )
}

const Time = () => {
  const [time, setTime] = useState(currentTime)

  const FocusOut = (e) => {
    
    if (timeFormat.test(e.target.value) || OneDigit.test(e.target.value) || TwoDigits.test(e.target.value) || ThreeDigits.test(e.target.value) || FourDigits.test(e.target.value) || FiveDigits.test(e.target.value) || SixDigits.test(e.target.value)) {
      
      ReactGA.event({
        category: "Input",
        action: "Type time",
        label: e.target.value,
      });
      
      if (timeFormat.test(e.target.value)){
        setCurrentTime(time)
      }

      if (OneDigit.test(e.target.value)){
        setCurrentTime(time+":00:00");
      }

      if (TwoDigits.test(e.target.value)){
        setCurrentTime(time+":00");
      }

      if (ThreeDigits.test(e.target.value)){
        time.split();
        setCurrentTime(time[0]+":"+time[1]+time[2]+":00");
      }

      if (FourDigits.test(e.target.value)){
        time.split();
        setCurrentTime(time[0]+time[1]+":"+time[2]+time[3]);
      }

      if (FiveDigits.test(e.target.value)){
        time.split();
        setCurrentTime("0"+time[0]+":"+time[1]+time[2]+":"+time[3]+time[4]);
      }

      if (SixDigits.test(e.target.value)){
        time.split();
        setCurrentTime(time[0]+time[1]+":"+time[2]+time[3]+":"+time[4]+time[5]);
      }
      
      if (currentDistance) {
        setCurrentSpeed(calculateSpeed(time, currentDistance))
        setCurrentPace(speedToPace(calculateSpeed(time, currentDistance)))
      }
      if (currentSpeed && !currentDistance) { setCurrentDistance(calculateDistance(time, currentSpeed))}
    }

    else if (!e.target.value){
      setCurrentTime(undefined)
    }
    else { 
      ReactGA.event({
        category: "Input",
        action: "Time input error",
        label: e.target.value,
      });
      alert('Wrong format buddy, please match HH:MM:SS or MM:SS')
      e.target.value=''
    }
    e.preventDefault();
  }

  return (
    
    <div className='Field Time'>
      <h2>Time</h2>
      <label className='unit'>
        <span>
          <input  
          name='Time'
          id='Time'
          type='text'
          inputMode="decimal"
          placeholder='00:50:00'
          value={time}
          onBlur={FocusOut}
          onChange={(e) => setTime(e.target.value.replace("." , ":").replace("," , ":"))}
          onKeyDown={handleKeyDown}
          />
        </span>
      </label>
    </div>
  )
}

const Pace = () => {
  const[pace, setPace] = useState(currentPace)
  
  const focusOut = (e) => {

    if (paceFormat.test(e.target.value) || OneDigit.test(e.target.value) || TwoDigits.test(e.target.value) || ThreeDigits.test(e.target.value)){
      
      ReactGA.event({
        category: "Input",
        action: "Type pace",
        label: e.target.value,
      });

      if (OneDigit.test(e.target.value) || TwoDigits.test(e.target.value)){
        setCurrentPace(pace+":00");
      }

      if (ThreeDigits.test(e.target.value)){
        pace.split()
        setCurrentPace(pace[0]+":"+pace[1]+pace[2]);
      }
      
      if (paceFormat.test(e.target.value)){
        setCurrentPace(pace)
      }

      setCurrentSpeed(paceToSpeed(pace))
      
      if (currentTime && currentDistance) {setCurrentTime(calculateTime(currentDistance, paceToSpeed(pace)))}
      if (currentDistance) {setCurrentTime(calculateTime(currentDistance, paceToSpeed(pace)))}
      if (currentTime && !currentDistance) { setCurrentDistance(calculateDistance(currentTime, paceToSpeed(pace)))}
    }

    else if (!e.target.value){
      setCurrentPace(undefined)
    }
    
    else { 
      ReactGA.event({
        category: "Input",
        action: "Pace input error",
        label: e.target.value,
      });
      alert('Wrong format buddy, please match M:SS')
      e.target.value=''
    }
    e.preventDefault();
  }

  return (
    <div className='Field Pace'>
      <h2>Pace</h2>
      <label className='unit'>
        <span>
          <input  
            name='Pace'
            id='Pace'
            type='text'
            inputMode="decimal"
            placeholder='5:00'
            value={pace}
            onBlur= {focusOut}
            onChange= {(e) => setPace(e.target.value.replace("." , ":").replace("," , "."))}
            onKeyDown={handleKeyDown}
            />
          </span>
      &#160;{toggle ? "min/mi" : "min/km"}</label>
    </div>
  )
}

const Speed = () => {
  const[speed, setSpeed] = useState(currentSpeed)

  const focusOut = (e) => {
    
    if (speedFormat.test(e.target.value) && e.target.value){
      ReactGA.event({
        category: "Input",
        action: "Type speed",
        label: e.target.value,
      });
      setCurrentSpeed(speed)
      setCurrentPace(speedToPace(speed))
      if (currentTime && currentDistance) {setCurrentTime(calculateTime(currentDistance, speedToPace(speed)))}
      if (currentDistance) {setCurrentTime(calculateTime(currentDistance, speed))}
      if (currentTime && !currentDistance) { setCurrentDistance(calculateDistance(currentTime, speed))}
    }
    else if (!e.target.value){
      setCurrentSpeed(undefined)
    }
    else { 
      ReactGA.event({
        category: "Input",
        action: "Speed input error",
        label: e.target.value,
      });
      alert('Wrong format')
      e.target.value=''
    }
    e.preventDefault();
  }

  return (
    
    <div className='Field Speed'>
      <h2>Speed</h2>
      <label className='unit'>
        <span>
          <input  
            id='Speed'
            type='text'
            inputMode="decimal"
            placeholder='12.00'
            value={speed}
            onChange= {(e) => setSpeed(e.target.value.replace("," , "."))}
            onBlur= {focusOut}
            onKeyDown={handleKeyDown}
          />
        </span>
        &#160;{toggle ? "mph" : "km/h"}</label>
    </div>
  )
}

const handleKeyDown = event => {
  if (event.key === 'Enter') {
    event.target.blur();
  }
};

function paceToSpeed(pace) {
  if (OneDigit.test(pace) || TwoDigits.test(pace)){
    pace=pace+":00"
  }

  if (ThreeDigits.test(pace)){
    pace.split();
    pace=(pace[0]+":"+pace[1]+pace[2]);
  }

  pace = pace.split(':');
  let paceInSec = Number(pace[0])*60 + Number(pace[1]);
  let speed = Math.round(((1000/paceInSec*3.6) + Number.EPSILON)*100)/100
  return(speed)
}

function speedToPace(speed) {
  let paceInSec=1/(Number(speed)/3.6)*1000
  let pace = Math.trunc(paceInSec/60) + ':' + Math.trunc(paceInSec%60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
  return(pace)
}

function calculateDistance(time, speed) {
  if (OneDigit.test(time)){
    time=time+":00:00";
  }

  if (TwoDigits.test(time)){
    time=time+":00";
  }

  if (ThreeDigits.test(time)){
    time.split();
    time=time[0]+":"+time[1]+time[2]+":00";
  }

  if (FourDigits.test(time)){
    time.split();
    time=time[0]+time[1]+":"+time[2]+time[3];
  }

  if (FiveDigits.test(time)){
    time.split();
    time="0"+time[0]+":"+time[1]+time[2]+":"+time[3]+time[4];
  }

  if (SixDigits.test(time)){
    time.split();
    time=time[0]+time[1]+":"+time[2]+time[3]+":"+time[4]+time[5];
  }
  
  time = time.split(':');
  let timeInSec
  if (time.length===3) {timeInSec = Number(time[0])*3600 + Number(time[1]*60) + Number(time[2])}
  if (time.length===2) {timeInSec = Number(time[0]*60) + Number(time[1])}
  let distance = Math.round((timeInSec*(speed/3600) + Number.EPSILON)*100)/100
  return(distance)
}

function calculateTime(distance, speed) {
  let timeInSec = distance / (speed/3600)
  let time = Math.trunc(timeInSec/3600).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ':' + Math.trunc((timeInSec%3600)/60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ':' + Math.trunc(timeInSec%60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
  return(time)
}

function calculateSpeed(time, distance){
  if (OneDigit.test(time)){
    time=time+":00:00";
  }

  if (TwoDigits.test(time)){
    time=time+":00";
  }

  if (ThreeDigits.test(time)){
    time.split();
    time=time[0]+":"+time[1]+time[2]+":00";
  }

  if (FourDigits.test(time)){
    time.split();
    time=time[0]+time[1]+":"+time[2]+time[3];
  }

  if (FiveDigits.test(time)){
    time.split();
    time="0"+time[0]+":"+time[1]+time[2]+":"+time[3]+time[4];
  }

  if (SixDigits.test(time)){
    time.split();
    time=time[0]+time[1]+":"+time[2]+time[3]+":"+time[4]+time[5];
  }
  
  time = time.split(':');
  let timeInSec
  if (time.length===3) {timeInSec = Number(time[0])*3600 + Number(time[1]*60) + Number(time[2])}
  if (time.length===2) {timeInSec = Number(time[0]*60) + Number(time[1])}
  let speed = Math.round((3600/timeInSec*distance + Number.EPSILON)*100)/100
  return(speed)
}

const [activated, setActivated] = useState(false);
const [refreshable, setRefreshable] = useState(false);
const [active, setActive] = useState(false);

const shareTimerImage = async () => {
  if (activated){
    ReactGA.event({
      category: "Action",
      action: "Click Share Image",
      label: "Share",
    });

    //create canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    //set canvas size
    canvas.width = 600;
    canvas.height = 600;

    //load background image
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = 'sharing-image.png';

    await new Promise(resolve => {
      img.onload = () => {
        //draw background image
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        //set text styles
        ctx.fillStyle = '#ffffff';
        ctx.font = 'italic 38px Gotham';
        ctx.textAlign = 'center';

        //draw title
        ctx.fillText('RUNNER', canvas.width/2, 90);
        ctx.fillText('CONVERTER', canvas.width/2, 130);

        //draw values lines
        ctx.textAlign = 'right';
        ctx.font = '56px Gotham';
        const gradient = ctx.createLinearGradient(150, 300, 430, 300);
        gradient.addColorStop(0, "#9515C2");
        gradient.addColorStop(1, "#3C5CFF");
        ctx.fillStyle = gradient;
        ctx.fillText(`${currentDistance}`, 375, 220);
        ctx.fillText(`${currentTime}`, 430, 310);
        ctx.fillText(`${currentPace}`, 310, 405);
        ctx.fillText(`${currentSpeed}`, 340, 500);

        //draw units lines
        ctx.font = '30px GothamLight';
        ctx.fillStyle = '#ffffff';
        ctx.fillText(`km`, 430, 220);
        ctx.fillText(``, 430, 310);
        ctx.fillText(`min/km`, 430, 405);
        ctx.fillText(`km/h`, 430, 500);

        canvas.toBlob(blob => {
          const data = {
            files: [new File([blob], 'runner-converter.png', {type: blob.type,}),],

          };

          //share
          if (navigator.canShare(data)) {
            if (navigator.share) {
              navigator
                .share(data)
                .then(() => {
                })
                .catch(error => {
                  console.error("Something went wrong", error);
                });
            }
          }
        }, 'image/png', 1);
        resolve();
      }
    });
  }
};

const shareTimerLink = () => {
  if (activated){
    ReactGA.event({
      category: "Action",
      action: "Click Share Link",
      label: "Share",
    });

    //build url
    const urlParams = new URLSearchParams();
    urlParams.set("d", currentDistance);
    urlParams.set("t", currentTime);
    urlParams.set("p", currentPace);
    urlParams.set("s", currentSpeed);
    const linkWithParams = "https://runner-converter.com/?" + urlParams.toString().replaceAll(".", "_");

          const data = {
            url: linkWithParams
          };

          //share
          if (navigator.canShare(data)) {
            if (navigator.share) {
              navigator
                .share(data)
                .then(() => {
                })
                .catch(error => {
                  console.error("Something went wrong", error);
                });
            }
          }
        };

      }

const ShareImgButton = () => {
  
  if(currentDistance && currentPace) {
    setActivated(true);
  }
  else {
    setActivated(false);
  }
  
  return(
    <button 
    className={activated ? "Button" : "Button disabled"}
    onClick={shareTimerImage}><FaImage /> Share an image
    </button>
    )
}

const ShareLinkButton = () => {
  
  if(currentDistance && currentPace) {
    setActivated(true);
  }
  else {
    setActivated(false);
  }
  
  return(
    <button 
    className={activated ? "Button" : "Button disabled"}
    onClick={shareTimerLink}><FaLink /> Share as a link
    </button>
    )
}

const SharingOptions = () => {

  return(
    
    <div className={active ? "open" : "closed"}>
      <div className="overlay" onClick={closeOverlay}>
      </div>
      <div className="sharingOptions">
        <div className="cross" onClick={closeOverlay}>
          <FiX />
        </div>
        <ShareLinkButton />
        <p>or</p>
        <ShareImgButton />
      </div>
  </div>
  )
}

function closeOverlay() {
  setActive(false);
  ReactGA.event({
    category: "Support",
    action: "Close sharing options",
    label: "Close",
  });
}

function openOverlay() {
  if(activated) {
    setActive(true);
    ReactGA.event({
      category: "Support",
      action: "Open sharing options",
      label: "Open",
    });
  }
}

const ShareButton = () => {
  
  if(currentDistance && currentPace && currentSpeed && currentTime) {
    setActivated(true);
  }
  else {
    setActivated(false);
  }

  return(
    <button 
    className={activated ? "Button" : "Button disabled"}
    onClick={openOverlay}><FiShare /> Share
    </button>
    )
}

const ClearButton = () => {
  
  if(currentDistance || currentPace || currentSpeed || currentTime) {
    setRefreshable(true);
  }
  else {
    setRefreshable(false);
  }

  return(
    <button 
    className={refreshable ? "Button" : "Button disabled"}
    onClick={clearInputs}><FaRedoAlt /> Reset
    </button>
    )
}

const AffiliationBlock = () => {

  var product = "Alphafly 3 Proto";
  var textLabel = "nike.com";
  var textLink = "https://tidd.ly/3RAdV0Z";
  var ctaLabel = "Buy it on Nike.com";
  var ctaLink = "https://tidd.ly/3RAdV0Z";
  var allLabel = "See all running gear";
  var allLink = "https://tidd.ly/3BnfwOr";

  const trackClick = (loc) => {
    ReactGA.event({
      category: "Support",
      action: "Click Brand Link",
      label: loc,
    })
  }

  return(
  <div className="affiliationBlock">
    <p>Check out the {product} on <a 
    href={textLink}
    className="affiliationCTA3 secondary"
    target="_blank"
    rel="noreferrer"
    onClick={() => trackClick("TOP")}
    >{textLabel}</a></p>
    <a href="https://tidd.ly/3RAdV0Z" target="_blank" rel="noreferrer"
    onClick={() => trackClick("IMG")}>
    <img src="shoepic1.jpg" alt="Running shoe 1"></img>
    <img src="shoepic2.jpg" alt="Running shoe 2"></img>
    <img src="shoepic3.jpg" alt="Running shoe 3"></img>
    </a>
    <a 
    href={ctaLink} 
    target="_blank"
    rel="noreferrer"
    className="affiliationCTA1"
    onClick={() => trackClick("CTA")}
    >{ctaLabel} <FiExternalLink />
    </a>

    <a 
    href={allLink}
    target="_blank"
    rel="noreferrer"
    className="affiliationCTA2 secondary"
    onClick={() => trackClick("SECONDARY")}
    >{allLabel}</a>
  </div>
  )
}

const ContentBlock = () => {
  return(
  <div className="contentBlock">
    <h2>Introducing the Ultimate Running Calculator for Distance, Pace, Time, and Speed</h2>
    <p>Looking for a reliable running calculator to enhance your training sessions? Look no further! Our advanced running calculator is your ultimate tool for accurately measuring various aspects of your runs. Whether you want to calculate your distance, pace, time, or speed, our versatile calculator has got you covered.</p>
    <h2>Accurately Measure Your Running Distance with Our Advanced Calculator</h2>
    <p>When it comes to determining your running distance, our running calculator takes the guesswork out of the equation. Simply enter your time and pace, and let our calculator instantly provide you with the precise distance covered during your run. Whether you're preparing for a marathon or tracking your daily jog, this feature will help you plan your routes more effectively.</p>
    <h2>Easily Determine Your Running Pace with Our Dedicated Calculator</h2>
    <p>Pace plays a crucial role in any running activity. Our running calculator offers a dedicated pace calculator that allows you to determine your pace effortlessly. By inputting your distance and time, you can obtain your average pace per mile or kilometer, empowering you to set realistic goals and monitor your progress over time.</p>
    <h2>Calculate Your Race Pace for Optimal Performance with Our Race Calculator</h2>
    <p>Are you a competitive runner looking to gauge your performance in a race? Our race calculator provides invaluable assistance in calculating your pace for different race distances. Simply enter the desired race distance, and our calculator will estimate the pace you need to maintain in order to achieve your target time. Stay motivated and push your limits with this powerful tool.</p>
    <h2>Optimize Your Training Schedule with Our Running Time Calculator</h2>
    <p>Time is of the essence, and our running calculator helps you optimize it. By entering your distance and pace, you can swiftly calculate your estimated running time. This feature is especially beneficial for planning training schedules or predicting your performance in upcoming races. Stay ahead of the game with accurate time calculations at your fingertips.</p>
    <h2>Enhance Your Performance by Calculating Your Running Speed</h2>
    <p>If speed is your focus, our running calculator has got you covered. Determine your running speed by entering your distance and time, and our calculator will promptly provide you with your average speed. Whether you're aiming to increase your pace or achieve a personal best, this tool offers valuable insights into your performance and progress.</p>
    <h2>Plan Your Race Strategy with Our Versatile Race Calculator</h2>
    <p>In addition to its diverse range of features, our running calculator also doubles as a race calculator. Whether it's a 5K, 10K, half marathon, or full marathon, our calculator can assist you in planning your race strategy. Simply input the desired distance, and our calculator will provide you with the essential pace information you need to excel on race day.</p>
  </div>
  )
}

if (isLoading) {
  return null;
}

return (
    <div className='container'>
      {/* <HelpButton /> */}
      {/* <UnitSwitch toggle={toggle} handleToggleChange={handleToggleChange} /> */}
      <Header />
      <WorldRecords />

        <Distance />
        <Time />
        <Pace /> 
        <Speed />

        <div className="actions">
          <ShareButton />
          <ClearButton />
        </div>

        <SharingOptions/>
        
        <AffiliationBlock />
        <ContentBlock/>
    </div>
  );
}

export default App;